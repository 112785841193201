import { authorizedFetch } from '~/auth'

const defaultFetcher = async (url: string) => {
  const res = await authorizedFetch(url)
  if (!res.ok) {
    console.log(res)
    throw new Error('Could not fetch data')
  }
  return res.json()
}

const blobFetcher = (url: string) =>
  authorizedFetch(url).then(r => {
    if (!r.ok) {
      throw new Error('Could not fetch profile picture')
    }
    return r.blob()
  })

export { blobFetcher, defaultFetcher }
