import { Bifrost, enUS, FloatingMessage } from '@intility/bifrost-react'
import { PropsWithChildren } from 'react'

// global css files from external packages
import '@intility/bifrost-react/dist/bifrost-app.css'

import Navigation from './components/Navigation'
import SWRProvider from './components/SWRProvider'

const App = ({ children }: PropsWithChildren) => {
  return (
    <SWRProvider>
      <Bifrost locale={enUS}>
        <Navigation>
          <FloatingMessage left>{children}</FloatingMessage>
        </Navigation>
      </Bifrost>
    </SWRProvider>
  )
}

export default App
