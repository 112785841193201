import { wrapCreateBrowserRouter } from '@sentry/react'
import {
  createBrowserRouter,
  createRoutesFromElements,
  Outlet,
  Route
} from 'react-router-dom'

import App from './App'
import ErrorPage from './components/ErrorPage'
import Home from './components/Home/Home'

/**
 * Sentry integration
 * @see https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
 */
const sentryCreateBrowserRouter = wrapCreateBrowserRouter(createBrowserRouter)

/**
 * The applications router, using react-router data APIs
 * @see https://reactrouter.com/en/main/routers/create-browser-router
 */
const router = sentryCreateBrowserRouter(
  createRoutesFromElements(
    <Route
      errorElement={
        <ErrorPage error={{ status: 500, info: 'Something went wrong' }} />
      }
      element={
        <App>
          <Outlet />
        </App>
      }
    >
      <Route index element={<Home />} />

      <Route
        path='*'
        element={<ErrorPage error={{ status: 404, info: 'Page Not Found' }} />}
      />
    </Route>
  )
)

export { router }
