import { faUser } from '@fortawesome/pro-solid-svg-icons'
import { Icon } from '@intility/bifrost-react'
import { useEffect, useMemo } from 'react'
import useSWRImmutable from 'swr/immutable'

import { blobFetcher } from '~/utils/fetchers'

const ProfilePicture = () => {
  const { data, error } = useSWRImmutable(
    'https://graph.microsoft.com/v1.0/me/photos/48x48/$value',
    blobFetcher,
    {
      errorRetryCount: 1
    }
  )

  const imgUrl = useMemo(() => data && URL.createObjectURL(data), [data])

  useEffect(
    () => () => {
      imgUrl && URL.revokeObjectURL(imgUrl)
    },
    [imgUrl]
  )

  if (!imgUrl || error) return <Icon icon={faUser} style={{ margin: '0px' }} />

  return (
    <img src={imgUrl} alt={''} style={{ borderRadius: '50%', width: '32px' }} />
  )
}

export default ProfilePicture
