import { Grid, Skeleton } from '@intility/bifrost-react'
import { useState } from 'react'

import useTools from '~/api/useTools'
import { ToolType } from '~/types/types'
import ToolModal from '../ToolModal/ToolModal'
import Card from './Card'
import SkeletonCard from './SkeletonCard'

const Starred = () => {
  const { data: starred, isLoading } = useTools({
    selectedFilters: ['starred']
  })

  const [selectedCard, setSelectedCard] = useState<ToolType | undefined>()
  const [openModal, setOpenModal] = useState(false)

  return (
    <>
      <Grid className='starred-container'>
        <Grid small={2} medium={3} large={5}>
          {!starred && isLoading ? (
            <Skeleton repeat={5}>
              <SkeletonCard />
            </Skeleton>
          ) : (
            <>
              {starred?.map(tool => (
                <Card
                  key={tool.id}
                  tool={tool}
                  setSelectedCard={setSelectedCard}
                  setOpenModal={setOpenModal}
                />
              ))}
            </>
          )}
        </Grid>
      </Grid>

      {selectedCard && (
        <ToolModal
          open={Boolean(selectedCard && openModal)}
          setOpen={setOpenModal}
          tool={selectedCard}
        />
      )}
    </>
  )
}

export default Starred
