import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { RouterProvider } from 'react-router-dom'

// https://bifrost.intility.com/#/Welcome%20to%20Bifrost/Get%20started/Installation
import '@intility/bifrost-react/dist/bifrost-app.css'
import Authentication from './auth/Authentication'
import { router } from './router'
import { initializeSentry } from './utils/initializeSentry'

// Set default theme to dark if no theme preference is set
if (!localStorage?.getItem('bfTheme')) {
  localStorage?.setItem('bfTheme', '"dark"')
}

const rootElement = document.getElementById('root')!
const root = createRoot(rootElement)

root.render(
  <StrictMode>
    <Authentication>
      <RouterProvider router={router} />
    </Authentication>
  </StrictMode>
)

initializeSentry()
