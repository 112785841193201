import { Drawer, Table } from '@intility/bifrost-react'
import { useState } from 'react'

import { ToolType } from '~/types/types'
import tableFormatter from '../../../utils/TableFormatter'
import DeleteToolModal from '../DeleteToolModal'
import ToolDrawerContent from '../Drawer/ToolDrawerContent'
import TableRow from './TableRow'
import './ToolsTable.css'

type ToolsTableProps = {
  tools: ToolType[]
  selectedTool?: ToolType
  setSelectedTool: React.Dispatch<React.SetStateAction<ToolType | undefined>>
}

const ToolsTable = ({
  tools,
  selectedTool,
  setSelectedTool
}: ToolsTableProps) => {
  const [editDrawerOpen, setEditDrawerOpen] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)

  return (
    <>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell></Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
            {tableFormatter.map(title => (
              <Table.HeaderCell
                key={title.key}
                className={`from-${title.size} cell-type-${title.key}`}
              >
                {title.name}
              </Table.HeaderCell>
            ))}
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {tools.length === 0 && (
            <Table.Row className='empty-results-row'>
              <Table.Cell className='empty-cell'></Table.Cell>
              <Table.Cell className='empty-cell'></Table.Cell>
              <Table.Cell className='cell-type-name'>
                No matching results
              </Table.Cell>
              <Table.Cell />
              <Table.Cell />
              <Table.Cell />
              <Table.Cell />
              <Table.Cell />
            </Table.Row>
          )}

          {tools.length > 0 &&
            tools.map(tool => (
              <TableRow
                key={tool.id}
                tool={tool}
                setSelectedTool={setSelectedTool}
                setEditDrawerOpen={setEditDrawerOpen}
              />
            ))}
        </Table.Body>
      </Table>

      <Drawer
        header='Update service'
        isOpen={editDrawerOpen}
        onRequestClose={() => setEditDrawerOpen(false)}
        overlay
        className='tool-drawer-container'
      >
        <ToolDrawerContent
          key={selectedTool?.id}
          setOpen={setEditDrawerOpen}
          tool={selectedTool}
          setShowDeleteModal={setShowDeleteModal}
        />
      </Drawer>

      <DeleteToolModal
        show={showDeleteModal}
        setShow={setShowDeleteModal}
        tool={selectedTool}
      />
    </>
  )
}

export default ToolsTable
