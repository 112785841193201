import { PropsWithChildren } from 'react'
import { SWRConfig } from 'swr'

import { defaultFetcher } from '~/utils/fetchers'

const SWRProvider = ({ children }: PropsWithChildren) => {
  const fetcher = defaultFetcher
  return <SWRConfig value={{ fetcher }}>{children}</SWRConfig>
}

export default SWRProvider
