import useSWR from 'swr'

import { EmployeeType } from '~/types/types'

type Props = {
  query: string
}

const useEmployees = ({ query }: Props) => {
  const { data, error, isValidating, mutate } = useSWR<EmployeeType[]>(
    query ? `employees?query=${query}` : null
  )

  // iterate data and return an array of options with value and label
  const options = data?.map(x => ({
    label: x.fullName,
    value: x
  }))

  return { data, error, isValidating, mutate, options }
}

export default useEmployees
