import { useEffect, useMemo } from 'react'
import useSWRImmutable from 'swr/immutable'

import { blobFetcher } from '~/utils/fetchers'

const useEmployeePicture = (id?: string) => {
  const swr = useSWRImmutable(
    id
      ? `https://graph.microsoft.com/v1.0/users/${id}/photos/48x48/$value`
      : null,
    blobFetcher,
    {
      errorRetryCount: 1
    }
  )

  const imgUrl = useMemo(
    () => swr.data && URL.createObjectURL(swr.data),
    [swr.data]
  )

  useEffect(
    () => () => {
      imgUrl && URL.revokeObjectURL(imgUrl)
    },
    [imgUrl]
  )

  return { ...swr, data: imgUrl }
}

export default useEmployeePicture
